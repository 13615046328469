import type { RouterConfig } from '@nuxt/schema'

export default {
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) => {
    // 移除 mobile 和 desktop 的路由
    return _routes.filter((item) => {
      return !item.path.startsWith('/desktop') && !item.path.startsWith('/mobile')
    })
  },
} satisfies RouterConfig
