import { defineStore } from 'pinia'
import type { QueryDistributionInfoResponse, SearchFilterConfigDto } from '~/apis/back/types'
import { CountryCodeMap } from '~/constant/countries'
import type { ICurrencyItem, ITicketInfoGroupItem } from '~/server/types/base'
import type { PartnerCityListRes } from '~/server/types/city.type'
import type { IIndexConfigRes } from '~/server/types/config.type'

export interface IIndexConfig {
  /* 票型信息 */
  ticketInfo?: ITicketInfoGroupItem[]
  /* 币种信息 */
  currencyInfo?: ICurrencyItem[]
  /* 合作品牌信息 */
  brandInfo?: string[]
  /* 分销商信息 */
  partnerInfo?: QueryDistributionInfoResponse
  /* 城市列表 */
  cityInfo?: PartnerCityListRes
  /* 榜单页filter Options */
  filterOpitonsInfo?: SearchFilterConfigDto
}

export const useCommonStore = defineStore('common', () => {
  const commonData = reactive<IIndexConfig>({
    ticketInfo: undefined, // 票型信息
    currencyInfo: undefined, // 币种列表信息
    brandInfo: [], // 品牌列表
    partnerInfo: undefined, // 分销商信息，包含分销商图片、里程信息
    cityInfo: {
      attrDestinationList: [],
      recommendDestinationList: [],
    }, // 城市列表、推荐城市列表
    filterOpitonsInfo: undefined, // 榜单页filter Options
  })

  // 获取首页配置
  async function getIndexConfig() {
    // 如果已经获取过数据，就不再请求
    if (
      commonData.ticketInfo
      && commonData.currencyInfo?.length
      && commonData.brandInfo
      && commonData.partnerInfo && commonData.filterOpitonsInfo
    ) {
      return
    }

    const { data } = await useAppFetch<IIndexConfigRes>('/api/config', { method: 'POST' })

    if (data) {
      const { ticketInfo, currencyInfo, brandInfo, partnerInfo, filterOpitonsInfo } = data.value as IIndexConfigRes

      commonData.ticketInfo = ticketInfo
      commonData.currencyInfo = currencyInfo
      commonData.brandInfo = brandInfo
      commonData.partnerInfo = partnerInfo
      commonData.filterOpitonsInfo = filterOpitonsInfo

      if (commonData.ticketInfo) {
        const filterConditionStore = useFilterConditionsStore().filterConditions

        for (const item of commonData.ticketInfo) {
          for (const sub of item.attrTagList) {
            if (sub.tagName === defaultTicketName) {
              if (!filterConditionStore.listTagList || !filterConditionStore.listTagList.length) {
                filterConditionStore.listTagList = [sub.tagId]
              }
              if (!filterConditionStore.tagList || !filterConditionStore.tagList.length) {
                filterConditionStore.tagList = [sub.tagId]
              }
              break
            }
          }
        }
      }
    }
  }

  async function getCityList() {
    const { data } = await useAppFetch<PartnerCityListRes>('/api/city', { method: 'POST' })
    if (data.value) {
      {
        // parse country，遍历所有数据，增加countryCode字段
        const countryList = data.value.attrDestinationList
        if (countryList) {
          for (const country of countryList) {
            country.countryCode = CountryCodeMap[country.countryName?.toLowerCase() ?? '']
          }
        }

        const recommendList = data.value.recommendDestinationList
        if (recommendList) {
          for (const country of recommendList) {
            country.countryCode = CountryCodeMap[country.countryName?.toLowerCase() ?? '']
          }
        }
      }
      commonData.cityInfo = data.value
    }
  }

  const getCurrencySymbol = computed(() => {
    const userInfoStore = useUserStore()
    if (commonData.currencyInfo) {
      const target = commonData.currencyInfo?.find(
        item => item.currency === userInfoStore.userInfo.currency,
      )
      return target?.symbol || ''
    }
    return ''
  })

  return { commonData, getIndexConfig, getCityList, getCurrencySymbol }
})
