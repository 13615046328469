import validate from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45page_45view_45global from "/build/middleware/01.page.view.global.ts";
import redirect_45global from "/build/middleware/redirect.global.ts";
import manifest_45route_45rule from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45page_45view_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}