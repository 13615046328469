export interface ILocaleMessage {
  [locale: string]: {
    [i18nKey: string]: string
  }
}

const defaultLocale = 'en-US'

export function useLocale() {
  const cookie = useCookie('i18n_locale')
  const locale = useState('i18n_locale', () => cookie.value || defaultLocale)
  // const locale = ref(defaultLocale)

  function watchLocale() {
    watchEffect(() => {
      const locales = useNuxtApp().payload.data?.locales || []
      const route = useRoute()
      let localeName = defaultLocale
      if (route.params.locale) {
        localeName = route.params.locale as string
      }
      else if (cookie.value && locales.includes(cookie.value)) {
        localeName = cookie.value
      }
      locale.value = localeName
      if (cookie.value !== localeName) {
        cookie.value = localeName
      }
    })
  }

  function changeLocale(newLocale: string) {
    const locales = useNuxtApp().payload.data?.locales || []
    if (!locales.includes(newLocale)) {
      return
    }
    const currentRoute = useRoute()
    const newRoute = {
      name: currentRoute.name,
      path: currentRoute.path,
      params: {
        ...currentRoute.params,
        locale: newLocale,
      },
      query: currentRoute.query,
      hash: currentRoute.hash,
    }
    locale.value = newLocale
    cookie.value = newLocale
    if (currentRoute.params.locale) {
      navigateTo(newRoute)
    }
    else {
      setTimeout(() => {
        window.location.reload()
      }, 10)
    }
  }

  return {
    locale,
    watchLocale,
    changeLocale,
  }
}
