<template>
  <section class="error-page">
    <div class="content">
      <h1>{{ error.statusCode }}</h1>
      <p v-if="error.statusCode === 404">
        Oops! The page you're looking for can't be found.
      </p>
      <p v-else>
        An error occurred. Please try again later.
      </p>
      <p v-if="error.message" class="error-message">
        {{ error.message }}
      </p>
      <nuxt-link to="/" class="btn">
        Go Back Home
      </nuxt-link>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 500,
        message: 'An unexpected error has occurred.',
      }),
    },
  },
}
</script>

  <style scoped>
  .error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: linear-gradient(135deg, #02b261, #04c481, #028751);
    color: #fff;
    text-align: center;
  }

  .content {
    max-width: 500px;
    padding: 20px;
  }

  h1 {
    font-size: 10rem;
    margin: 0;
    animation: bounce 1s infinite;
  }

  p {
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .error-message {
    font-size: 1rem;
    padding: 10px;
    border-radius: 5px;
  }

  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #fff;
    color: #02b261;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s;
  }

  .btn:hover {
    background-color: #02b261;
    color: #fff;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  </style>
