import type { DistStyleDto } from '~/apis/back/types'

export function useGlobalCssVars() {
  const commonStore = useCommonStore()

  const defaultThemeColor = '#07b883'
  const defaultTitleColor = '#FFFFFF'

  const distStyle = computed<Required<DistStyleDto>>(() => {
    const { listPageStyle, indexPageStyle } = commonStore.commonData?.partnerInfo?.distStyle || {}
    return {
      listPageStyle: {
        bgColor: listPageStyle?.bgColor || defaultThemeColor,
        viewButtonColor: listPageStyle?.viewButtonColor || defaultThemeColor,
        cashbackDescColor: listPageStyle?.cashbackDescColor || defaultThemeColor,
        starColor: listPageStyle?.starColor || defaultThemeColor,
        scoreColor: listPageStyle?.scoreColor || defaultThemeColor,
        otherColor: listPageStyle?.otherColor || listPageStyle?.bgColor || defaultThemeColor,
        titleColor: listPageStyle?.titleColor || defaultTitleColor,
        subTitleColor: listPageStyle?.subTitleColor || defaultTitleColor,
      },
      indexPageStyle: {
        bgColor: indexPageStyle?.bgColor || defaultThemeColor,
        otherColor: indexPageStyle?.otherColor || indexPageStyle?.bgColor || defaultThemeColor,
        titleColor: indexPageStyle?.titleColor || defaultTitleColor,
        subTitleColor: indexPageStyle?.subTitleColor || defaultTitleColor,
      },
    }
  })

  watchEffect(() => {
    const route = useRoute()
    useHead({
      meta: [
        {
          name: 'theme-color',
          content: route.path === '/attractions' ? distStyle.value.indexPageStyle?.bgColor : distStyle.value.listPageStyle?.bgColor,
        },
      ],
    })
  })

  useHead({
    style: [
      `:root {
  --tc-color-primary: ${distStyle.value.listPageStyle?.otherColor};
  --tc-color-primary1: ${distStyle.value.listPageStyle?.otherColor}; /* #28C193 */
  --tc-color-bg-index: ${distStyle.value.indexPageStyle?.bgColor};
  --tc-color-title-index: ${distStyle.value.indexPageStyle?.titleColor};
  --tc-color-subtitle-index: ${distStyle.value.indexPageStyle?.subTitleColor};
  --tc-color-bg-list: ${distStyle.value.listPageStyle?.bgColor};
  --tc-color-title-list: ${distStyle.value.listPageStyle?.titleColor};
  --tc-color-subtitle-list: ${distStyle.value.listPageStyle?.subTitleColor};
  --tc-color-view-button: ${distStyle.value.listPageStyle?.viewButtonColor};
  --tc-color-cashback-desc: ${distStyle.value.listPageStyle?.cashbackDescColor};
  --tc-color-star: ${distStyle.value.listPageStyle?.starColor};
  --tc-color-score: ${distStyle.value.listPageStyle?.scoreColor};
  --van-primary-color: var(--tc-color-primary) !important;
  --el-color-primary: var(--tc-color-primary) !important;
${[3, 5, 7, 8, 9].map(i => `  --el-color-primary-light-${i}: ${mixColor(distStyle.value.listPageStyle.bgColor || defaultThemeColor, '#ffffff', i / 10)} !important;`).join('\n')}
${[2].map(i => `  --el-color-primary-dark-${i}: ${mixColor(distStyle.value.listPageStyle.bgColor || defaultThemeColor, '#000000', i / 10)} !important;`).join('\n')}
}`,
    ],
  })
}
