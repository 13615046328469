import { defineStore } from 'pinia'
import type { AttrDestinationDto } from '~/server/types/city.type'

const dayjs = useDayjs()
interface FilterConditions {
  /* 时间戳 */
  date?: number
  detailDate?: number
  selectedCity?: AttrDestinationDto
  tagList: number[]
  listTagList: number[]
  filterIdList: string[]
  range: {
    min: number
    max: number
    currency: string
  }
}

export const defaultTicketName = '1 Adult'

export const useFilterConditionsStore = defineStore('filter-conditions', () => {
  const commonStore = useCommonStore()
  const useInfoStore = useUserStore()
  const filterConditions: FilterConditions = reactive({
    date: undefined, // 日期 时间戳
    detailDate: new Date().getTime(),
    selectedCity: {}, // 用户票型
    listTagList: [],
    tagList: [], // 票型
    filterIdList: [], // 筛选条件
    range: {
      min: 0,
      max: 0,
      currency: '',
    },
  })

  const priceRange = computed(() => {
    const opiton = (commonStore.commonData.filterOpitonsInfo?.priceRanges || []).find(item => item.currency === useInfoStore.userInfo.currency)
    return opiton || {}
  })

  const setFilterOptionsInit = () => {
    const arr1 = filterConditions.listTagList.map((id) => {
      return id < 4 ? `1001_${id}` : `1002_${id}`
    })
    const arr2 = filterConditions.filterIdList.filter(id => !id.startsWith('1001_') && !id.startsWith('1002_'))
    const filterIdList = [...new Set([...arr2, ...arr1])]
    filterConditions.filterIdList = filterIdList
  }
  function getOptionsByCodes() {
    setFilterOptionsInit()
    return filterConditions.filterIdList.flatMap((item) => {
      const [category, optionCode] = item.split('_')
      const filterItem = (commonStore.commonData.filterOpitonsInfo?.filters || []).find(f => f.category === category)
      return filterItem ? (filterItem?.options || []).filter(opt => opt.optionCode === optionCode) || [] : []
    })
  }
  const handleListParams = () => {
    const params = {
      filters: getOptionsByCodes(),
      priceMin: priceRange?.value?.currency === filterConditions.range.currency ? (filterConditions.range.min || filterConditions.range.min === 0 ? filterConditions.range.min || 0 : -1) : priceRange?.value?.min,
      priceMax: priceRange?.value?.currency === filterConditions.range.currency ? (filterConditions.range.max || filterConditions.range.max === 0) ? filterConditions.range.max : -1 : priceRange?.value?.max,
    }
    return params
  }

  // 记录筛选条件
  const setRange = (range: { min: number, max: number, currency: string }) => {
    filterConditions.range = range
  }

  // lisTagList 转化 filterIdList
  const needFilterIdList = computed(() => filterConditions.filterIdList.map((id) => {
    if (id.startsWith('1001_') || id.startsWith('1002_')) {
      return id.split('_')[1]
    }
    return ''
  }).filter(id => id))

  const setFilterOpitonToCommonListTagList = () => {
    filterConditions.listTagList = (needFilterIdList.value || []).map(id => Number(id))
  }

  const getRequestFilterConditions = () => {
    return {
      destinationId: filterConditions.selectedCity?.destinationId,
      tagIdList: filterConditions.tagList,
      date: dayjs(filterConditions.date).format('YYYY-MM-DD'),
      detailDate: dayjs(filterConditions.detailDate).format('YYYY-MM-DD'),
    }
  }

  const filterDateRef = computed(() => ({
    get: (isDetail: boolean) => (isDetail ? filterConditions.detailDate : filterConditions.date),
    set: (isDetail: boolean, newValue: number) => {
      if (isDetail) {
        filterConditions.detailDate = newValue
      }
      filterConditions.date = newValue
    },
  }))

  const filterTagListRef = computed(() => ({
    get: (isDetail: boolean) =>
      isDetail ? filterConditions.tagList : filterConditions.listTagList,
    set: (isDetail: boolean, newValue: any[]) => {
      if (isDetail) {
        filterConditions.tagList = newValue
      }
      else {
        filterConditions.listTagList = newValue
      }
    },
  }))

  const resetDetailFilter = () => {
    filterConditions.detailDate = filterConditions.date
    filterConditions.tagList = [...filterConditions.listTagList]
  }

  const resetHomeFilter = () => {
    filterConditions.date = undefined
    filterConditions.selectedCity = {}
  }

  return { filterConditions, getRequestFilterConditions, filterTagListRef, filterDateRef, resetDetailFilter, setFilterOpitonToCommonListTagList, setFilterOptionsInit, setRange, handleListParams, resetHomeFilter }
})
