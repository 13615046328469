import revive_payload_client_VtHdQeQWY1 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ESAPmJrBjn from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rJIL9tb5Ij from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PGU2syP3mg from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uGGKbWuqu2 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HJJ2p2i1lz from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Y6iW4OuHmh from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZVpcc2TzG2 from "/build/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_XnArQN2SP7 from "/build/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_eslint@9.12.0_jiti@2.3.3__ioredis@5.4._zcwzenneth44zoq63zzrlae2qa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WrE3qXM6W9 from "/build/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_shqjxl2uiuugynczfgjsmfohku/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/build/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/build/.nuxt/element-plus-injection.plugin.mjs";
import vant_lazyload_plugin_W13KNvxip6 from "/build/.nuxt/vant-lazyload.plugin.mjs";
import plugin_liyViRA2ax from "/build/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/build/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/build/.nuxt/unocss.mjs";
import ssr_plugin_odeOqNLaBD from "/build/node_modules/.pnpm/@vueuse+nuxt@11.1.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.11_esl_7aupw5n6tawvdqeam76ljw2g5m/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import plugin_rS5xb60Z0v from "/build/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_n7tokq7p5orfvfqa7nvbtjgnzi/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import deivce_Irsa0E2qZA from "/build/plugins/deivce.ts";
import fetch_14DdzPiXUB from "/build/plugins/fetch.ts";
export default [
  revive_payload_client_VtHdQeQWY1,
  unhead_ESAPmJrBjn,
  router_rJIL9tb5Ij,
  payload_client_PGU2syP3mg,
  navigation_repaint_client_uGGKbWuqu2,
  check_outdated_build_client_HJJ2p2i1lz,
  chunk_reload_client_Y6iW4OuHmh,
  plugin_vue3_ZVpcc2TzG2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_XnArQN2SP7,
  plugin_WrE3qXM6W9,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  vant_lazyload_plugin_W13KNvxip6,
  plugin_liyViRA2ax,
  plugin_wy0B721ODc,
  unocss_MzCDxu9LMj,
  ssr_plugin_odeOqNLaBD,
  plugin_rS5xb60Z0v,
  deivce_Irsa0E2qZA,
  fetch_14DdzPiXUB
]