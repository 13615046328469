import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', () => {
  const { hostname } = useRequestURL()

  // eslint-disable-next-line node/prefer-global/process
  const host = ref(process.env.NODE_ENV === 'development' ? '' : hostname)

  // 是否是消耗币场景
  const isBurnCoinsScene = computed(() => {
    return host.value === 'partnership.azgo.ai'
  })

  return {
    host,
    isBurnCoinsScene,
  }
})
