import { ENUM_EventType } from '~/enums'

export default defineNuxtRouteMiddleware((to) => {
  const filterConditionsStore = useFilterConditionsStore()
  const gtm = useGtm()
  const { locale } = useLocale()

  if (to.meta.pageCategory) {
    const pageViewParams = {
      event: ENUM_EventType.PageView,
      city: filterConditionsStore?.filterConditions?.selectedCity?.destinationName || '',
      city_id: filterConditionsStore?.filterConditions?.selectedCity?.destinationId || '',
      campaign_id: to.query.campaign_id || '',
      campaign_name: to.query.campaign_name || '',
      locale: locale.value || '',
      country_code: filterConditionsStore?.filterConditions?.selectedCity?.countryCode || '',
      page_name: to.meta.pageCategory || '',
    }
    gtm?.trackEvent(pageViewParams)
  }
})
