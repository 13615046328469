export interface IColorObj {
  r: number
  g: number
  b: number
  a?: number
}

/**
 * 255颜色值转16进制颜色值
 * @param n 255颜色值
 * @returns hex 16进制颜色值
 */
const toHex = (n: number) => `${n > 15 ? '' : 0}${n.toString(16)}`

/**
 * 颜色对象转化为16进制颜色字符串
 * @param colorObj 颜色对象
 */
function toHexString(colorObj: IColorObj) {
  const { r, g, b, a = 1 } = colorObj
  const aStr = a === 1 ? '' : toHex(Math.floor(a * 255))
  return `#${aStr}${toHex(r)}${toHex(g)}${toHex(b)}`
}

/**
 * 颜色对象转化为rgba颜色字符串
 * @param colorObj 颜色对象
 */
function toRgbaString(colorObj: IColorObj, n = 10000) {
  const { r, g, b, a = 1 } = colorObj
  return `rgba(${r},${g},${b},${Math.floor(a * n) / n})`
}

/**
 * 16进制颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
function parseHexColor(color: string) {
  let hex = color.slice(1)
  let a = 1
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }
  if (hex.length === 8) {
    a = Number.parseInt(hex.slice(6), 16) / 255
    hex = hex.slice(0, 6)
  }
  const bigint = Number.parseInt(hex, 16)
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
    a,
  } as IColorObj
}

/**
 * rgba颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
function parseRgbaColor(color: string) {
  const arr = color.match(/(\d(\.\d+)?)+/g) || []
  const res = arr.map((s: string) => Number.parseInt(s, 10))
  return {
    r: res[0],
    g: res[1],
    b: res[2],
    a: Number.parseFloat(arr[3]),
  } as IColorObj
}

/**
 * 颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
function parseColorString(color: string) {
  if (color.startsWith('#')) {
    return parseHexColor(color)
  }
  else if (color.startsWith('rgb')) {
    return parseRgbaColor(color)
  }
  else if (color === 'transparent') {
    return parseHexColor('#00000000')
  }
  throw new Error(`color string error: ${color}`)
}

/**
 * 16进制颜色字符串转化为rgba颜色字符串
 * @param hex 16进制颜色字符串
 * @returns rgba颜色字符串
 */
export function hexToRgba(hex: string) {
  const colorObj = parseColorString(hex)
  return toRgbaString(colorObj)
}

/**
 * rgba颜色字符串转化为16进制颜色字符串
 * @param rgba rgba颜色字符串
 * @returns 16进制颜色字符串
 */
export function rgbaToHex(rgba: string) {
  const colorObj = parseColorString(rgba)
  return toHexString(colorObj)
}

export function mixColor(color1: string, color2: string, ratio = 0.5) {
  const c1 = parseColorString(color1)
  const c2 = parseColorString(color2)
  const r = Math.round(c1.r * (1 - ratio) + c2.r * ratio)
  const g = Math.round(c1.g * (1 - ratio) + c2.g * ratio)
  const b = Math.round(c1.b * (1 - ratio) + c2.b * ratio)
  const a = (c1.a || 1) * (1 - ratio) + (c2.a || 2) * ratio
  return toHexString({ r, g, b, a })
}
