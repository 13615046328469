import { defineStore } from 'pinia'
import type { ILoginRes } from '~/server/types/login.type'

export const useUserStore = defineStore('userInfo', () => {
  const userInfo = reactive({
    memberId: '',
    distCode: 'azgo',
    accessToken: '',
    currency: '',
  })

  const getRowUserInfo = () => {
    return toRaw(userInfo)
  }

  async function fetchLoginToken() {
    const { data } = await useAppFetch<ILoginRes>('/api/login', {
      method: 'POST',
    })
    if (data) {
      userInfo.accessToken = data.value?.accessToken || ''
      return true
    }
  }

  return { userInfo, getRowUserInfo, fetchLoginToken }
}, {
  persist: {
    storage: persistedState.cookiesWithOptions({
      maxAge: 1 * 24 * 60 * 60 * 1000,
    }),
  },
})
